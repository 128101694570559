import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from 'gatsby-image';

const Avatar = () => {

	const data = useStaticQuery(graphql`
		query {
			file(relativePath: {eq: "avatar.png"}) {
				childImageSharp{
					fluid(maxWidth: 800) {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
  `)

  return (
		<Img
			fluid={data.file.childImageSharp.fluid}
			style={{
				margin: '2rem 4rem',
				overflow: 'visible',
				width: '40%',
			}}
			imgStyle={{
				borderRadius: '50%',
				boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
			}}
		/>
  )
}

export default Avatar;