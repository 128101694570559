import React from "react";
import styled from "@emotion/styled"

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 1rem;

  input {
      display: none;
  }

  input:checked ~ label {
      background: #e9f259;
  }

  input:checked ~ #description {
      animation: showDescription 0.25s forwards;
  }

  input:not(:checked) ~ #description {
      animation: hideDescription 0.25s forwards;
  }

  input:checked ~ #marker {
      background: pink;
      writing-mode: vertical-rl;
      text-align: center;
  }

  input:not(:checked) ~ #marker {
      background: yellow;
      text-align: center;
  }

  @keyframes showDescription {
      from {
          visibility: hidden;
          opacity: 0;
          height: 0px;
          margin: 0 0;
      }
      1% {
          visibility: visible;
      }
      50% {
          opacity: 1;
          margin: 1rem 0;
      }
      to {
          opacity: 1;
          height: auto;
          margin: 1rem 0;
      }
  }

  @keyframes hideDescription {
      from {
          visibility: visible;
          opacity: 1;
          height: auto;
          margin: 1rem 0;
      }
      40% {
          opacity: 0;
          margin: 0 0;
      }
      99% {
          height: 0px;
      }
      to {
          visibility: hidden;
          opacity: 0;
          height: 0px;
          margin: 0 0;
      }
  }
`

const KeyWordWrapper = styled.div`
  margin-bottom: 1rem;
  max-width: 60%;
  background: transparent;
`

const KeyWord = styled.label`
  padding: 0 0.25em;
  margin-bottom: 1rem;
  font-size: 1.4em;
  user-select: none;
  background: transparent;
  border-radius: 0.1em;

  transition: background 0.25s;
  
  :hover {
    background: #e9f259;
  }
`

const Description = styled.div`
  font-size: 1.0em;
  overflow: hidden;
  padding-top: 1em;
  background: transparent;

  #skillList {
      padding-left: 1em;
      text-align: left;
  }
`

const About = () => {

  return (
      <Wrapper>
        <KeyWordWrapper>
            <input type={"checkbox"} id={"leader"} name={"keywords"}/>
            <KeyWord for="leader">leader</KeyWord>
            <Description id={"description"}>
                <p><em>"Redundancy."</em></p>
                <p>My main goal as a leader is to become superfluous. I do this by empowering and strengthening my teammates to make the group rely less on me to lead them.</p>
                <p>A group in which all members feel a sense of ownership is more <em>resilient</em> than a group which looks to its leader for directions.</p>
            </Description>
        </KeyWordWrapper>

        <KeyWordWrapper>
            <input type={"checkbox"} id={"developer"} name={"keywords"}/>
            <KeyWord for="developer">developer</KeyWord>
            <Description id={"description"}>
                <p><em>"Because it's fun!"</em></p>
                <p>I think software development is super fun, and have taught myself most of what i know.</p>
                <p>Being self-taught means I'm skilled at overcoming unpredicted obstacles which allways appear when developing software.</p>
                <p>Tools I feel fully comfortable using:</p>
                <ul id={"skillList"}>
                    <li><strong>C#</strong> (.NET Framework, .NET Core, WinForms, WPF)</li>
                    <li><strong>Python</strong> (Numpy, Pandas, Matplotlib, BeautifulSoup, ...)</li>
                    <li><strong>Matlab</strong></li>
                    <li><strong>Bash</strong></li>
                    <li><strong>HTML, CSS, Gatsby.JS, GraphQL</strong></li>
                    <li><strong>OpenCV</strong></li>
                    <li><strong>Visual Studio, Vi/Vim, Jupyter</strong></li>
                    <li><strong>macOS, Linux, Windows</strong></li>
                    <li><strong>Git, Jira</strong></li>
                </ul>
                <br/>
                <p>Tools which I've worked with and am semi-confident using:</p>
                <ul id={"skillList"}>
                    <li><strong>Javascript</strong> (React, Scripting)</li>
                    <li><strong>Swift, XCode</strong></li>
                    <li><strong>Ruby</strong> (Nokogiri, Gosu)</li>
                    <li><strong>Blender</strong></li>
                    <li><strong>Docker</strong></li>
                    <li><strong>Tensorflow, Keras, Pytorch</strong></li>
                </ul>

            </Description>
        </KeyWordWrapper>

        <KeyWordWrapper>
            <input type={"checkbox"} id={"engineer"} name={"keywords"}/>
            <KeyWord for="engineer">engineer</KeyWord>
            <Description id={"description"}>
                <p><em>"M.Sc.Eng."</em></p>
                <p>I've got a Master's degree in <a href="https://www.chalmers.se/sv/utbildning/program-pa-grundniva/Sidor/Teknisk-fysik.aspx">Engineering Physics</a> from <a href="https://www.chalmers.se/en/Pages/default.aspx">Chalmers University of Technology</a>.</p>

                <p>I enrolled in the Master's programme <a href="https://www.chalmers.se/en/education/programmes/masters-info/Pages/Systems-control-and-mechatronics.aspx">Systems, Control and Mechatronics</a>.</p>

                <p>In our master's thesis <a href="http://publications.lib.chalmers.se/records/fulltext/255471/255471.pdf"><em>"Abstractive Document Summarisation using Generative Adversarial Networks"</em></a>, my thesis partner Johan and I investigated automatic document summarisation using deep learning.</p>
            </Description>
        </KeyWordWrapper>
      </Wrapper>
  )
}

export default About;
